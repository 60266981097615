/*resCarousel Css*/

.outt {
    display: none;
}

.leftRs {
    position: absolute;
    margin: auto;
    padding: 0px;
    top: 0;
    bottom: 0;
    z-index: 100;
    left: -25px;
    width: 25px;
    height: 25px;
    box-shadow: 1px 2px 10px -1px rgba(0, 0, 0, .3);
    border-radius: 999px;
}

.rightRs {
    position: absolute;
    margin: auto;
    padding: 0px;
    top: 0;
    right: -25px;
    bottom: 0;
    z-index: 100;
    width: 25px;
    height: 25px;
    box-shadow: 1px 2px 10px -1px rgba(0, 0, 0, .3);
    border-radius: 999px;
}

.resCarousel {
    /*float: left;
    overflow: hidden;*/
    width: 100%;
    position: relative;
}

.resCarousel-inner {
    overflow-x: hidden;
    white-space: nowrap;
    font-size: 0;
    vertical-align: top;
}

.resCarousel-inner .item {
    display: inline-block;
    font-size: 14px;
    white-space: initial;
}


/*banner*/

.banner {
    overflow: hidden !important;
}


/*tile css*/

.resCarousel-inner .item .tile {
    background: white;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    margin: 5px;
}


/*animation*/

.resCarousel[data-animator="lazy"] .item {
    transition: .6s ease all;
}

@media (max-width: 767px) {
    .leftRs,
    .rightRs {
        /*display: none;*/
    }
    .resCarousel-inner {
        overflow-x: auto;
    }
}


/*resCarousel Css End*/